export default [
    //documents --------------------
    {
        path: '/docs/orders',
        name: 'docs-orders',
        component: () => import('@/views/customer/docs/Orders.vue'),
        meta: {
            resource: 'docs-orders',
            action: 'page',
        },
    },
    {
        path: '/docs/shipments',
        name: 'docs-shipments',
        component: () => import('@/views/customer/docs/Shipments.vue'),
        meta: {
            resource: 'docs-shipments',
            action: 'page',
        },
    },
    {
        path: '/docs/credit-memos',
        name: 'docs-credit-memos',
        component: () => import('@/views/customer/docs/CreditMemos.vue'),
        meta: {
            resource: 'docs-credit-memos',
            action: 'page',
        },
    },
    {
        path: '/docs/validated-credit-memos',
        name: 'docs-validated-credit-memos',
        component: () => import('@/views/customer/docs/ValidatedCreditMemos.vue'),
        meta: {
            resource: 'docs-validated-credit-memos',
            action: 'page',
        },
    },
    {
        path: '/docs/validated-invoices',
        name: 'docs-validated-invoices',
        component: () => import('@/views/customer/docs/ValidateInvoices.vue'),
        meta: {
            resource: 'docs-validated-invoices',
            action: 'page',
        },
    },
    {
        path: '/docs/returns',
        name: 'docs-returns',
        component: () => import('@/views/customer/docs/Returns.vue'),
        meta: {
            resource: 'docs-returns',
            action: 'page',
        },
    },
    {
        path: '/docs/invoices',
        name: 'docs-invoices',
        component: () => import('@/views/customer/docs/Invoices.vue'),
        meta: {
            resource: 'docs-invoices',
            action: 'page',
        },
    },
    {
        path: '/docs/billing-docs',
        name: 'docs-billing-documents',
        component: () => import('@/views/customer/docs/BillingDocs.vue'),
        meta: {
            resource: 'docs-billing-documents',
            action: 'page',
            contentRenderer: 'sidebar-left-detached',
            contentClass: 'ecommerce-application',
        },
    },
    {
        path: '/actuality',
        name: 'arrivals',
        component: () => import('@/views/customer/actuality/Actualities.vue'),
        meta: {
            resource: 'arrivals',
            action: 'page',
            contentRenderer: 'sidebar-left-detached',
            contentClass: 'ecommerce-application',
        },
    },
    {
        path: '/catalog',
        name: 'catalog',
        component: () => import('@/views/customer/catalog/Catalog.vue'),
        meta: {
            resource: 'catalog',
            action: 'page',
            contentRenderer: 'sidebar-left-detached',
            contentClass: 'ecommerce-application',
        },
    },
    {
        path: '/',
        name: 'new-order',
        component: () => import('@/views/customer/order/NewOrder.vue'),
        meta: {
            resource: 'new-order',
            action: 'page',
        },
    },
    {
        path: '/users',
        name: 'sub-accounts',
        component: () => import('@/views/customer/users/Users.vue'),
        meta: {
            resource: 'sub-accounts',
            action: 'page',
        },
    },
    {
        path: '/users/new',
        name: 'add-new-user',
        component: () => import('@/views/customer/users/NewUser.vue'),
        meta: {
            resource: 'add-new-user',
            action: 'page',
        },
    },
    {
        path: '/claims',
        name: 'claims',
        component: () => import('@/views/customer/Claims.vue'),
        meta: {
            resource: 'claims',
            action: 'page',
        },
    },
    {
        path: '/return',
        name: 'return-proposal',
        component: () => import('@/views/customer/return/Proposal.vue'),
        meta: {
            resource: 'return-proposal',
            action: 'page',
        },
    },
    {
        path: '/return-new',
        name: 'return-proposal-new',
        component: () => import('@/views/customer/return/NewProposal.vue'),
        meta: {
            resource: 'return-proposal',
            action: 'page',
        },
    },
    {
        path: '/purchase-history',
        name: 'purchase-history',
        component: () => import('@/views/customer/PurchaseHistory.vue'),
        meta: {
            resource: 'purchase-history',
            action: 'page',
        },
    },
    {
        path: '/basket/:slug',
        name: 'basket',
        component: () => import('@/views/customer/basket/Basket.vue'),
        meta: {
            resource: 'new-order',
            action: 'page',
        },
    },
]
