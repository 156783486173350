import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'
import VueMeta from 'vue-meta';

// import * as Sentry from "@sentry/vue";
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/acl'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import '@/libs/sweet-alerts'
import '@/libs/tour'
// BSV Plugin Registration
Vue.use(BootstrapVue)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(VueMeta);

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')



// Sentry.init({
//   App,
//   dsn: "https://adee03e0cd5a8189a71e5aa50db72259@o4507253316321280.ingest.de.sentry.io/4507253338275920",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: [/^https:\/\/pharmaconnect\.net/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });


Vue.config.productionTip = false
export const eventBus = new Vue();
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')