import Vue from 'vue'
import VueRouter from 'vue-router'
import customer from "./customer";
import administrator from "./administrator";
import { canNavigate } from '@/libs/acl/routeProtection'
import supervisor from "./supervisor";
import supplier from "./supplier";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...customer,
    ...administrator,
    ...supervisor,
    ...supplier,
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/password',
      name: 'password',
      component: () => import('@/views/ChangePassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/pages/not-authorized',
      name: 'misc-not-authorized',
      component: () => import('@/views/error/NotAuthorized.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error',
      name: 'error',
      component: () => import('@/views/error/Error.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/not-affected',
      name: 'not-affected',
      component: () => import('@/views/error/NotAffected.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/faq',
      name: 'pages-faq',
      component: () => import('@/views/faq/Faq.vue'),
      meta: {
        action: "page",
        resource: "faq",
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/account-setting/AccountSetting.vue'),
      meta: {
        resource: "profile",
        action: "page",
      },
    },
    //------------------------------
    //articles
    {
      path: '/products-list',
      name: 'products-list',
      component: () => import('@/views/product/ProductsList.vue'),
      meta: {
        resource: 'products-list',
        action: 'page',
      },
    },
    {
      path: '/product/:slug',
      name: 'product-details',
      component: () => import('@/views/product/ProductDetails.vue'),
      meta: {
        action: 'page',
        resource: 'product-details',
        contentClass: 'ecommerce-application',
      },
    },
    //------------------------------
    {
      path: '*',
      redirect: 'error',
    },
  ],
})

router.beforeEach((to, _, next) => {

  if (!canNavigate(to)) {
    if (to.fullPath == "/")
      return next({ name: 'auth-login' })
    else
      return next({ name: 'misc-not-authorized' })
  }

  return next()
});

export default router
