import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // baseURL: 'http://127.0.0.1:444/pharmaConnect/api/',
  // baseURL: '/pharmaconnect/',
  // baseURL: 'https://pharmaconnect.net/devpharma/',
  baseURL: 'https://pharmaconnect.net/pharmaconnect/',
})

export const DIRECTORY_FILES_PATH = "https://www.pharmaconnect.net/docserver/"
// export const DIRECTORY_FILES_PATH = "https://www.pharmaconnect.net/docserverdev/"

Vue.prototype.$serverpath = DIRECTORY_FILES_PATH
Vue.prototype.$http = axiosIns
export default axiosIns