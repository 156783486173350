export default [
    {
        path: '/supervisor/dashboard',
        name: 'supervisor-dashboard',
        component: () => import('@/views/supervisor/Dashboard.vue'),
        meta: {
            resource: 'supervisor-dashboard',
            action: 'page',
        },
    },
    {
        path: '/supervisor/companies',
        name: 'supervisor-companies',
        component: () => import('@/views/supervisor/company/List.vue'),
        meta: {
            resource: 'supervisor-companies',
            action: 'page',
        },
    },
    {
        path: '/supervisor/pharmacy',
        name: 'supervisor-pharmacy',
        component: () => import('@/views/supervisor/pharmacy/List.vue'),
        meta: {
            resource: 'supervisor-pharmacy',
            action: 'page',
        },
    },
    {
        path: '/supervisor/pharmacy-new',
        name: 'supervisor-pharmacy-new',
        component: () => import('@/views/supervisor/pharmacy/New.vue'),
        meta: {
            resource: 'supervisor-pharmacy',
            action: 'page',
        },
    },
    {
        path: '/supervisor/vendor',
        name: 'supervisor-vendor',
        component: () => import('@/views/supervisor/vendor/List.vue'),
        meta: {
            resource: 'supervisor-vendor',
            action: 'page',
        },
    },
    {
        path: '/supervisor/vendor-new',
        name: 'supervisor-vendor-new',
        component: () => import('@/views/supervisor/vendor/New.vue'),
        meta: {
            resource: 'supervisor-vendor',
            action: 'page',
        },
    },
    {
        path: '/supervisor/customers/:slug',
        name: 'supervisor-customers',
        component: () => import('@/views/supervisor/company/CustomerByCompany.vue'),
        meta: {
            resource: 'supervisor-customers',
            action: 'page',
        },
    },
    {
        path: '/supervisor/suppliers/:slug',
        name: 'supervisor-suppliers',
        component: () => import('@/views/supervisor/company/SupplierByCompany.vue'),
        meta: {
            resource: 'supervisor-suppliers',
            action: 'page',
        },
    },
    {
        path: '/supervisor/items',
        name: 'supervisor-item-list',
        component: () => import('@/views/supervisor/item/ItemList.vue'),
        meta: {
            resource: 'supervisor-item-list',
            action: 'page',
        },
    },
    {
        path: '/supervisor/item/:code',
        name: 'supervisor-item-sheet',
        component: () => import('@/views/supervisor/item/ItemSheet.vue'),
        meta: {
            resource: 'supervisor-item-sheet',
            action: 'page',
        },
    },
    {
        path: '/supervisor/supp/items/:code',
        name: 'supervisor-supp-items',
        component: () => import('@/views/supervisor/vendor/Items.vue'),
        meta: {
            resource: 'supervisor-item-sheet',
            action: 'page',
        },
    },
    {
        path: '/supervisor/families',
        name: 'supervisor-family',
        component: () => import('@/views/supervisor/item/Family.vue'),
        meta: {
            resource: 'supervisor-family',
            action: 'page',
        },
    },
    {
        path: '/supervisor/sub-families',
        name: 'supervisor-subFamily',
        component: () => import('@/views/supervisor/item/SubFamily.vue'),
        meta: {
            resource: 'supervisor-subFamily',
            action: 'page',
        },
    },
    {
        path: '/supervisor/dci',
        name: 'supervisor-dci',
        component: () => import('@/views/supervisor/item/DCI.vue'),
        meta: {
            resource: 'supervisor-dci',
            action: 'page',
        },
    },
    {
        path: '/supervisor/company-items/:slug',
        name: 'supervisor-company-items',
        component: () => import('@/views/supervisor/item/CompanyItems.vue'),
        meta: {
            resource: 'supervisor-company-items',
            action: 'page',
        },
    },
    {
        path: '/supervisor/logs',
        name: 'supervisor-middleware-logs',
        component: () => import('@/views/supervisor/log/Log.vue'),
        meta: {
            resource: 'supervisor-middleware-logs',
            action: 'page',
        },
    },
    {
        path: '/supervisor/notifications',
        name: 'supervisor-notifications',
        component: () => import('@/views/supervisor/notification/List.vue'),
        meta: {
            resource: 'supervisor-notifications',
            action: 'page',
        },
    },
    {
        path: '/supervisor/matching-items',
        name: 'supervisor-matching-items',
        component: () => import('@/views/supervisor/item/MatchingItems.vue'),
        meta: {
            resource: 'supervisor-matching-items',
            action: 'page',
        },
    },
]
